<template>
 <div class="main-page">
   <div class="ui-title-large title">Приветствуем в системе ис 3D</div>
   <div class="form-name">Введите номер заявки для перехода на страницу заявки</div>
   <div class="form-wrapper">
     <BaseForm
       :handleSubmit='pushToRequestPage'
       class="ui-to-request-form"
     >
       <template v-slot:form-content>
         <BaseInputText
           label="Номер заявки"
           name="requestId"
           placeholder="Номер заявки"
         />
         <div class="btn-wrapper">
           <BaseButton
             :disabled="isSubmitting"
             label="Перейти"
           />
         </div>
       </template>
     </BaseForm>
   </div>
 </div>
</template>

<script src="./MainPage.ts" lang="ts"/>
<style src="./MainPage.scss" lang="scss"/>
