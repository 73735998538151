import { defineComponent, onBeforeMount, onUnmounted } from 'vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { useRouter } from 'vue-router';
import ROUTE_NAMES from '@/router/route.names.json';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { REQUEST_ACTIONS_TYPE } from '@/store/names/action.name';
import appendToastMessage from '@/utils/appendToastMessage';
import DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { useStore } from 'vuex';
import { ErrorStatus } from '@/services/utils/errors/errorType';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import { object, string } from 'yup';
import { useForm } from 'vee-validate';

export default defineComponent({
  name: 'MainPage',

  components: {
    BaseInputText,
    BaseForm,
    BaseButton
  },

  setup() {
    const { push } = useRouter();
    const { dispatch } = useStore();

    const schema = object({
      requestId: string()
        .required(DICTIONARY.REQUIRED)
    });

    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: schema
    });

    const pushToRequestPage = handleSubmit(async(values) => {
      try {
        await dispatch(`${MODULES_NAMES.REQUEST}/${REQUEST_ACTIONS_TYPE.GET_REQUEST_DATA}`, values.requestId);
        await push({ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: values.requestId! } });
      } catch (err) {
        switch (err?.response?.status) {
          case ErrorStatus.NOT_FOUND:
            return appendToastMessage(DICTIONARY.REQUEST_NOT_FOUND);
          case ErrorStatus.NO_PERMISSIONS:
            return appendToastMessage(DICTIONARY.NO_PERMISSIONS);
        }
      }
    });

    onBeforeMount(() => {
      document.querySelector('body')!.classList.add('body-main-page');
    });

    onUnmounted(() => {
      document.querySelector('body')!.classList.remove('body-main-page');
    });

    return {
      handleSubmit,
      isSubmitting,
      pushToRequestPage
    };
  }
});
